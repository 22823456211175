// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.

var CKEDITOR_BASEPATH = "/assets/ckeditor/";

import "../stylesheets/active_admin";
import "@activeadmin/activeadmin";

require("trix");
require("@rails/actiontext");

// import "./vendor/assets/javascripts/ckeditor/ckeditor";
